import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import "@fontsource/roboto";
import "@fontsource/material-icons";

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] =
    'Bearer ' + store.getters['auth/getAccessToken'];

// Toaster
import Toaster from '@meforma/vue-toaster';

createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(Toaster, {
        position: 'top-right',
        duration: 4000,
    })
    .mount('#app');
