import { createStore } from 'vuex';
import auth from './modules/auth';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';

export default createStore({
    state: {
        name: null,
    },
    getters: {
        getName(state) {
            return state.name;
        },
    },
    mutations: {
        setName(state, name) {
            state.name = name;
        },
    },
    actions: {
        async stopGame({}, roomId) {
            return axios
                .post(
                    process.env.VUE_APP_DEFAULT_SERVER_URL +
                        '/admin/room/close-game',
                    { room: roomId }
                )
                .then((res) => {
                    return res.data;
                })
                .catch((err) => console.log(err));
        },
        async deleteRoom({}, roomId) {
            return axios
                .delete(
                    process.env.VUE_APP_DEFAULT_SERVER_URL +
                        '/admin/room/id/' +
                        roomId
                )
                .then((res) => res)
                .catch((err) => console.log(err));
        },
        async deleteCardset({}, cardsetId) {
            return axios
                .delete(
                    process.env.VUE_APP_DEFAULT_SERVER_URL +
                        '/admin/cardset/id/' +
                        cardsetId
                )
                .then((res) => res)
                .catch((err) => console.log(err));
        },
    },
    modules: {
        auth,
    },
    plugins: [createPersistedState()],
});
