import axios from 'axios';

export default {
    namespaced: true,
    state: {
        accessToken: null,
        refreshToken: null,
    },
    getters: {
        getAccessToken(state) {
            return state.accessToken;
        },
        getRefreshToken(state) {
            return state.refreshToken;
        },
        hasTokens(state) {
            return !(state.accessToken == null || state.refreshToken == null);
        },
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
        },
        setRefreshToken(state, token) {
            state.refreshToken = token;
        },
    },
    actions: {
        async login({ commit, getters }, loginObj) {
            return axios
                .post(process.env.VUE_APP_AUTH_SERVER_URL + '/login', loginObj)
                .then((res) => {
                    commit('setAccessToken', res.data.accessToken);
                    commit('setRefreshToken', res.data.refreshToken);

                    delete axios.defaults.headers.common['Authorization'];
                    axios.defaults.headers.common['Authorization'] =
                        'Bearer ' + getters['getAccessToken'];

                    return { msg: 'Erfolgreich angemeldet.' };
                })
                .catch((err) => {
                    throw { msg: 'Falsche Anmeldedaten.' };
                });
        },
        logout({ commit, getters }) {
            const body = {
                token: getters.getRefreshToken,
            };
            // const header: AxiosRequestHeaders = {
            //     'Content-Type': 'application/json',
            // };
            axios.post(process.env.VUE_APP_AUTH_SERVER_URL + '/logout', body);
            commit('setAccessToken', null);
            commit('setRefreshToken', null);
        },
        async checkAndRefreshTokens({ commit, getters, dispatch }) {
            return axios
                .get(process.env.VUE_APP_DEFAULT_SERVER_URL + '/auth/me')
                .then((res) => res)
                .catch(async (err) => {
                    // If there is no response just exit (cant handle anything then)
                    if (!err.response) throw 'Login';
                    // If response code not 403 its not an invalid token
                    if (err.response.status != 403) throw 'Login';

                    // Invalid Access Token - Try to get new one with refresh Token
                    return axios
                        .post(
                            process.env.VUE_APP_AUTH_SERVER_URL + '/token',
                            {
                                token: getters['getRefreshToken'],
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            }
                        )
                        .then((res) => {
                            // We got an access Token -> save it and repeat request
                            commit('setAccessToken', res.data.accessToken);
                            delete axios.defaults.headers.common[
                                'Authorization'
                            ];
                            axios.defaults.headers.common['Authorization'] =
                                'Bearer ' + res.data.accessToken;
                            return res;
                        })
                        .catch((err) => {
                            // Cant get new access Token -> Delete local Tokens and go to Login
                            dispatch('logout');
                            throw 'Login';
                        });
                });
        },
    },
};
