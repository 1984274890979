import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('../views/admin/Admin.vue'),
        redirect: { name: 'Dashboard' },
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                name: 'Dashboard',
                path: 'dashboard',
                component: () => import('../views/admin/Dashboard.vue'),
            },
            // {
            //     name: 'Create Game',
            //     path: 'game/create',
            //     component: () => import('../views/admin/CreateGame.vue'),
            // },
            {
                name: 'Edit Game',
                path: 'game/edit/:roomid',
                props: true,
                component: () => import('../views/admin/EditGame.vue'),
            },
            // {
            //     name: 'Create Cardset',
            //     path: 'cardset/create',
            //     component: () => import('../views/admin/CreateCardset.vue'),
            // },
            {
                name: 'Edit Cardset',
                path: 'cardset/edit/:cardsetid',
                props: true,
                component: () => import('../views/admin/EditCardset.vue'),
            },
        ],
    },
    {
        path: '/game/:code',
        name: 'Game',
        props: true,
        component: () => import('../views/Game.vue'),
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('../views/NotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // No Tokens but Auth => Login Page
        if (!store.getters['auth/hasTokens']) return next({ name: 'Login' });

        // Validate Access Token
        return store
            .dispatch('auth/checkAndRefreshTokens')
            .then((res) => {
                return next();
            })
            .catch((err) => {
                return next({ name: 'Login' });
            });
    } else {
        return next();
    }
});

export default router;
