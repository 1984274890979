<template>
    <header>
        <nav id="nav">
            <img
                class="g_pointer"
                :src="aasLogo"
                alt="Logo Amadeu Antonio Stiftung"
                @click="redirect('https://www.amadeu-antonio-stiftung.de/')"
            />
            <h1 class="g_pointer" @click="$router.push({ name: 'Home' })">
                Anno Domini
            </h1>
            <div class="links">
                <div v-if="authenticated">
                    <router-link :to="{ name: 'Dashboard' }">Admin</router-link>
                    <a @click="logoutEvent">Logout</a>
                </div>
                <div v-else>
                    <router-link :to="{ name: 'Login' }">Login</router-link>
                </div>
            </div>
        </nav>
    </header>
    <main>
        <router-view :key="$route.key" />
    </main>
    <footer>
        <div class="logos">
            <img
                class="g_pointer"
                :src="aasLogo"
                alt="Logo Amadeu Antonio Stiftung"
                @click="redirect('https://www.amadeu-antonio-stiftung.de/')"
            />
            <!-- :src="aasLogo" -->
            <img
                class="g_pointer"
                :src="ejaLogo"
                alt="Logo Evangelische Jugendarbeit Bautzen-Kamenz"
                @click="redirect('https://eja-online.de/')"
            />
        </div>
        <div>&copy; Amadeu Antonio Stiftung</div>
    </footer>
</template>

<script>
import aasLogo from "@/assets/img/AAS-Logo.svg";
import ejaLogo from "@/assets/img/eja-Logo-white.svg";
import { mapActions, mapGetters } from "vuex";
import resizeFooter from "@/assets/js/footerResize";

export default {
    data() {
        return {
            aasLogo: aasLogo,
            ejaLogo: ejaLogo,
        };
    },
    created() {
        window.addEventListener("resize", resizeFooter);
    },
    mounted() {
        this.$nextTick(() => {
            resizeFooter();
        });
    },
    updated() {
        this.$nextTick(() => {
            resizeFooter();
        });
    },
    methods: {
        ...mapActions({
            logout: "auth/logout",
        }),
        logoutEvent() {
            this.logout();
            this.$toast.success("Ausgeloggt.");
            this.$router.push({ name: "Login" });
        },
        redirect(url) {
            window.open(url, "_blank");
        },
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/hasTokens",
        }),
    },
};
</script>

<style lang="sass">
:root
    font-size: $default_font_size

.c-toast-container--top
    top: 5rem !important

.material-icons
    user-select: none

body
    margin: 0
    padding: 0

#app
    font-family: $font
    color: $tertiary_gray

a
    text-decoration: none
    color: inherit
    cursor: pointer

    &.router-link-exact-active
        color: $primary_green

.blue
    color: $tertiary_blue

.g_pointer
    cursor: pointer

.g_caps
    text-transform: uppercase

.g_form
    > div
        display: grid
        grid-template-columns: 1fr 2fr
        grid-template-areas: "label input"
        gap: 1rem
        margin: .5rem

        label, *.label
            grid-area: label
            text-align: right

        input, *.input, select, textarea
            grid-area: input
            text-align: left

        input[type=submit], button.submit
            width: fit-content

        p
            margin: 0

.g_button
    font-size: inherit
    background-color: $tertiary_blue
    border: 2px solid $tertiary_blue
    border-radius: 1em
    padding: .5em 1em
    color: #FFF
    transition-property: color, background-color
    transition-duration: 200ms
    transition-timing-function: ease-out
    cursor: pointer

    &:hover
        background-color: #fff
        color: $tertiary_blue

.g_h1
    color: $primary_green
    width: fit-content
    margin-inline: auto
    font-size: 2rem

    &::after
        content: ""
        display: block
        height: 3px
        margin-top: 5px
        width: 70%
        margin-inline: auto
        background-color: $primary_green

.g_containers
    display: grid
    width: 80%
    gap: 5%
    grid-template-columns: 1fr 1fr
    margin-inline: auto
    margin-top: 3rem

    > div
        padding: 2rem
        border-radius: 2rem
        box-shadow: 0 0 10px $tertiary_gray

        h2
            margin-top: 0

.g_container
    padding: 2rem
    border-radius: 2rem
    box-shadow: 0 0 10px $tertiary_gray
    width: fit-content
    margin: 1rem auto

    h2
        margin-top: 0

// HEADER SPECIFIC
header
    margin-bottom: 3rem

    &::after
        content: ""
        display: block
        height: 1px
        background-color: $tertiary_gray
        opacity: 0.2
        margin: 0 5%

#nav
    display: grid
    grid-template-columns: min-content 1fr min-content
    padding: 1rem 15%
    align-items: center
    gap: 4rem

    img
        height: 5em
    h1
        font-size: 2em
        font-weight: bold
        margin: 0

    a
        font-size: 1.4em

    .links
        div
            display: flex
            gap: 1rem

// MAIN SPECIFIC
main
    text-align: center

// FOOTER SPECIFIC
footer
    bottom: 0
    width: 100%
    box-sizing: border-box
    margin-top: 5rem
    padding: 1rem
    color: #FFF
    background-color: $tertiary_gray
    text-align: center

    div
        margin: 2rem 0

        &:first-child
            margin-top: 0
        &:last-child
            margin-bottom: 0

    .logos
        img
            height: 4em
            margin: 0 1rem
            transition-property: transform
            transition-duration: 300ms
            transition-timing-function: ease-out

            &:hover
                transform: scale(1.2)
</style>
