function resizeFooter() {
    const footer = document.querySelector('footer');
    if (!footer) return;

    footer.style.position = 'static';
    footer.style.bottom = 'initial';
    if (footer.offsetTop + footer.offsetHeight <= window.innerHeight) {
        footer.style.position = 'absolute';
        footer.style.bottom = '0';
    }
}

export default resizeFooter;
