<template>
    <form @submit.prevent="joinGame">
        <input
            id="code"
            type="text"
            v-model="code"
            placeholder="CODE"
            required
            autofocus
        />
        <input type="submit" value="Spiel beitreten" />
    </form>

    <div class="content">
        <h1 class="g_h1">Anno Domini - The Conspiracy Edition</h1>
        <div class="images" ref="images">
            <img :src="img1" alt="" />
            <img :src="img2" alt="" />
            <img :src="img3" alt="" />
            <img :src="img5" alt="" />
            <img :src="img9" class="vertical" alt="" />
            <img :src="img6" alt="" />
            <img :src="img8" alt="" />
            <img :src="img10" alt="" />
        </div>
    </div>
</template>

<script>
import img1 from "@/assets/img/png/1.png";
import img2 from "@/assets/img/png/2.png";
import img3 from "@/assets/img/png/3.png";
import img5 from "@/assets/img/png/5.png";
import img6 from "@/assets/img/png/6.png";
import img8 from "@/assets/img/png/8.png";
import img9 from "@/assets/img/png/9.png";
import img10 from "@/assets/img/png/10.png";

import resizeFooter from "@/assets/js/footerResize";

export default {
    name: "Home",
    data() {
        return {
            code: "",
            img1: img1,
            img2: img2,
            img3: img3,
            img5: img5,
            img6: img6,
            img8: img8,
            img9: img9,
            img10: img10,
        };
    },
    mounted() {
        this.$nextTick(() => {
            let imgarr = this.$refs.images.querySelectorAll("img");
            for (let img of imgarr) {
                img.addEventListener("load", resizeFooter);
            }
        });
    },
    methods: {
        joinGame() {
            if (this.code)
                this.$router.push({
                    name: "Game",
                    params: { code: this.code },
                });
        },
    },
};
</script>

<style lang="sass" scoped>
form
    margin-top: -2%
    margin-inline: auto
    border-radius: 1rem
    color: $tertiary_gray

    input
        margin: 1rem .5rem
        display: inline-block
        border: none
        border-radius: 1rem
        padding: .7rem
        font: inherit
        font-size: 1.6rem
        text-align: center
        border: 2px solid $tertiary_blue
        transition: all 100ms linear

    input[type="text"]
        width: 8rem
        background-color: $tertiary_blue
        color: #FFF
        text-transform: uppercase

        &:focus
            outline: none
            background-color: #FFF
            color: $tertiary_blue

        &::placeholder
            color: inherit
            opacity: .7

    input[type="submit"]
        background-color: #FFF
        color: $tertiary_blue
        cursor: pointer

        &:hover
            background-color: $tertiary_blue
            color: #FFF

    &::after
        display: block
        content: ""
        background-color: $tertiary_blue
        opacity: .7
        height: 2px
        width: 50%
        margin-block: 1rem
        margin-inline: auto

.content
    margin-top: 3rem

    .images
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        margin-top: 3rem

        img
            width: 100%

        .vertical
            grid-row-end: span 2
</style>